
.dsp-packages-list {
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
    min-height: 250px;

    .login-alert {
        padding: 50px 25px; 
    }

    .logo {
        width: 60px;
    }
    .table-responsive {
        min-height: 350px;
    }
    .table {
        thead {
            tr th:first-child {
                //border-top: none;
            }
            tr {
                th {
                    cursor: pointer;
                    color: #6c757d;
                    font-size: 1.1rem;
                }
            }
        }
        tbody {
            tr {
                td {
                    height: 90px;
                    font-size: 1.0rem;
                    .provider {
                        .value {
                            font-weight: bold;
                        }
                        .badge {
                            padding: 5px;
                            margin-right: 5px;
                            font-weight: normal;
                            font-size: 10px;
                            background: #FCC615;
                        }
                    }
                }
            }
            .deprecated {
                background-color: #ffe5e7!important;
            }
            .selected {
                background-color: #e0fbe3!important;
            }
        }
    }

    .services-filter {
        .dropdown {
            display: inline;
        }
    }
    .search-filter {
        .search {
            padding-left: 0px;
        }
        .dropdown {
            display: inline;
        }
    }
    .search-input {
        //min-width: 150px;
        width: 200px;
        display: inline;
        margin-left: 15px;
        padding-top: 2px;
    }
    .form-group {
        margin-bottom: 0;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #FCC615;
        background-color: #FCC615;
    }
}