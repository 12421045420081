@import url(https://fonts.googleapis.com/css?family=Kaushan+Script|Open+Sans);
.header {
  background-color: #101032 !important; }
  .header .navbar-brand {
    color: #ffffff !important; }
    .header .navbar-brand img {
      width: 150px; }
  .header .navbar-collapse {
    margin-top: 24px; }
  .header .navbar-nav .nav-link {
    color: #cbdceb; }
  .header .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }
  .header .navbar-nav .nav-link:focus,
  .header .navbar-nav .nav-link:hover {
    color: #FCC615; }
  .header .navbar-nav .nav-link.active {
    color: #cbdceb; }
  .header .navbar-nav .nav-link.active:hover {
    color: #FCC615; }

.footer .liquid-apps-logo {
  width: 200px; }

.footer .aloha-eos-logo {
  height: 63px; }

.footer .contact a {
  font-size: 30px;
  margin-right: 10px; }

.footer img.logo {
  width: 200px; }

.footer .copyright {
  margin-top: 0px;
  font-size: 0.8rem; }

.footer h5 {
  font-size: 1.1rem; }

.modal {
  color: #202047; }
  .modal .modal-header {
    background: #ffffff; }
    .modal .modal-header .close {
      color: #202047 !important; }
  .modal .modal-body {
    background: #ffffff;
    border-radius: 3px; }

.table thead tr th {
  vertical-align: middle; }

.table tbody tr td {
  vertical-align: middle; }

.dsp-packages-list {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  min-height: 250px; }
  .dsp-packages-list .login-alert {
    padding: 50px 25px; }
  .dsp-packages-list .logo {
    width: 60px; }
  .dsp-packages-list .table-responsive {
    min-height: 350px; }
  .dsp-packages-list .table thead tr th {
    cursor: pointer;
    color: #6c757d;
    font-size: 1.1rem; }
  .dsp-packages-list .table tbody tr td {
    height: 90px;
    font-size: 1.0rem; }
    .dsp-packages-list .table tbody tr td .provider .value {
      font-weight: bold; }
    .dsp-packages-list .table tbody tr td .provider .badge {
      padding: 5px;
      margin-right: 5px;
      font-weight: normal;
      font-size: 10px;
      background: #FCC615; }
  .dsp-packages-list .table tbody .deprecated {
    background-color: #ffe5e7 !important; }
  .dsp-packages-list .table tbody .selected {
    background-color: #e0fbe3 !important; }
  .dsp-packages-list .services-filter .dropdown {
    display: inline; }
  .dsp-packages-list .search-filter .search {
    padding-left: 0px; }
  .dsp-packages-list .search-filter .dropdown {
    display: inline; }
  .dsp-packages-list .search-input {
    width: 200px;
    display: inline;
    margin-left: 15px;
    padding-top: 2px; }
  .dsp-packages-list .form-group {
    margin-bottom: 0; }
  .dsp-packages-list .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #FCC615;
    background-color: #FCC615; }

.dsp-list {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  min-height: 250px; }
  .dsp-list .logo {
    width: 64px; }
  .dsp-list .login-alert {
    padding: 50px 25px; }
  .dsp-list .table thead tr th {
    cursor: pointer;
    color: #3131ff; }
  .dsp-list .table thead tr th {
    border-top: none; }
  .dsp-list .table thead tr th {
    cursor: pointer;
    color: #6c757d;
    font-size: 1.1rem; }
  .dsp-list .table tbody tr td {
    height: 90px;
    font-size: 1.0rem; }
    .dsp-list .table tbody tr td .provider .value {
      font-weight: bold; }
    .dsp-list .table tbody tr td .provider .badge {
      padding: 5px;
      margin-right: 5px;
      font-weight: normal;
      font-size: 10px;
      background: #FCC615; }
  .dsp-list .table tbody .deprecated {
    background-color: #ffe5e7 !important; }
  .dsp-list .table tbody .selected {
    background-color: #e0fbe3 !important; }

.stakes-list {
  background: #ffffff;
  border-radius: 3px;
  min-height: 250px; }
  .stakes-list .login-alert {
    padding: 50px 25px; }

.my-profile {
  background: #ffffff;
  border-radius: 3px;
  min-height: 250px;
  color: #000000; }
  .my-profile .login-alert {
    padding: 50px 25px; }
  .my-profile .balances {
    padding: 35px; }

.package-details {
  background: #ffffff;
  border-radius: 3px;
  min-height: 250px;
  color: #000000;
  padding: 30px 5px; }
  .package-details .logo img {
    width: 200px; }
  .package-details .name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 25px; }
  .package-details .card {
    margin-bottom: 15px; }
    .package-details .card .card-title {
      font-weight: bold;
      font-size: 18px; }
  .package-details .btn-primary {
    margin-bottom: 15px; }

.provider-details {
  background: #ffffff;
  border-radius: 3px;
  color: #000000; }
  .provider-details .logo img {
    width: 200px; }
  .provider-details .name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 25px; }
  .provider-details .card .card-title {
    font-weight: bold;
    font-size: 18px; }
  .provider-details .social-media ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center; }
    .provider-details .social-media ul li {
      display: inline;
      margin: 7px; }
      .provider-details .social-media ul li a, .provider-details .social-media ul li a:hover, .provider-details .social-media ul li a:visited, .provider-details .social-media ul li a:focus {
        color: #464748;
        font-size: 17px; }

body {
  background: #202047;
  color: #ffffff; }

.row {
  margin-right: 0px;
  margin-left: 0px; }

a, a:visited {
  color: #dd9d26; }

a:hover {
  color: #f5ae2a; }

h1 {
  font-family: 'Kaushan Script', cursive;
  font-size: 2.0rem;
  margin: 15px 0 15px 0;
  text-align: center; }

.balances .caption {
  font-weight: bold; }

.btn {
  padding: 6px 10px;
  border: none;
  border-radius: 0.8rem; }
  .btn.btn-primary {
    background: #FCC615;
    font-size: 0.9rem;
    color: #000000; }
  .btn.btn-primary:focus, .btn.btn-primary:hover, .btn.btn-primary:active {
    background: #FCC615 !important;
    color: #000000;
    box-shadow: none; }
  .btn.login-required, .btn.login-required:hover, .btn.login-required:active, .btn.login-required:focus {
    background: #FCC615;
    border-radius: 0.8rem;
    padding: 6px 10px; }
  .btn.btn-primary:not(:disabled):not(.disabled).active {
    color: #000000; }
  .btn.btn-secondary {
    background-color: #cbdceb;
    color: #000000; }
  .btn.btn-secondary:hover {
    background: #FCC615;
    color: #000000; }
  .btn.btn-secondary a, .btn.btn-secondary a:hover {
    color: #ffffff;
    font-size: 0.9rem;
    color: #000000; }
  .btn.btn-secondary a:hover {
    text-decoration: none;
    color: #000000; }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #000000;
  background-color: #FCC615;
  border-radius: 0.8rem; }

.pointer {
  cursor: pointer; }

.full-width {
  width: 100% !important; }

.blur {
  opacity: 0.2; }

.table-hover tbody tr:hover {
  background-color: transparent;
  background-color: initial; }

.dropdown-menu {
  background-color: #eaeaea; }

.dropdown-header {
  font-weight: bolder; }

.dropdown-item.active, .dropdown-item:active {
  background-color: #FCC615; }

