.header {
    background-color: #101032 !important;

    .navbar-brand {
        color: #ffffff !important;

        img {
            width: 150px;
        }
    }
    
    .navbar-collapse {
        margin-top: 24px;
    }

    .navbar-nav .nav-link {
        color: #cbdceb;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    .navbar-nav .nav-link:focus,
    .navbar-nav .nav-link:hover {
        color: #FCC615;
    }

    .navbar-nav .nav-link {
        &.active {
            color: #cbdceb;
        }
        &.active:hover {
            color: #FCC615;
        }
    }
}