.provider-details {
    background: #ffffff;
    border-radius: 3px;
    color: #000000;
    .logo {
        img {
            width: 200px;
        }
    }
    .name {
        font-size: 24px;
        font-weight: bold;
        margin-top: 25px;
    }
    .card {
        .card-title {
            font-weight: bold;
            font-size: 18px;
        }
    }
    .social-media {
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;
            li {
                display: inline;
                margin: 7px;
                a, a:hover, a:visited, a:focus {
                    color: #464748;
                    font-size: 17px;
                }
            }
        }
    }
}