@import "./header.scss";
@import "./footer.scss";
@import "./modal.scss";
@import "./table.scss";
@import "./dsp-packages-list.scss";
@import "./dsp-list.scss";
@import "./stakes-list.scss";
@import "./profile.scss";
@import "./package-details.scss";
@import "./provider-details.scss";
@import url("https://fonts.googleapis.com/css?family=Kaushan+Script|Open+Sans");

body {
    background: #202047;
    color: #ffffff;
}
.row {
    margin-right: 0px;
    margin-left: 0px;
}
a, a:visited {
    //color: #72DDFB;
    color: #dd9d26;
}
a:hover {
    color: #f5ae2a;
}
h1 {
    font-family: 'Kaushan Script', cursive;
    font-size: 2.0rem;
    margin: 15px 0 15px 0;
    text-align: center;
    //color: #31e0ff;
}
.balances {
    .caption {
        font-weight: bold;
    }
}

.btn {
    &.btn-primary {
        background: #FCC615;
        font-size: 0.9rem;
        color: #000000;
    }
    &.btn-primary:focus, &.btn-primary:hover, &.btn-primary:active {
        background: #FCC615 !important;
        color: #000000;
        box-shadow: none;
    }
    &.login-required, &.login-required:hover, &.login-required:active, &.login-required:focus {
        background: #FCC615;
        border-radius: 0.8rem;
        padding: 6px 10px;
    }

    &.btn-primary:not(:disabled):not(.disabled).active {
        color: #000000;
    }
    padding: 6px 10px;
    border: none;
    border-radius: 0.8rem;
    &.btn-secondary {
        background-color: #cbdceb;
        //background-color: rgba(114, 221, 251, 0.28);
        color: #000000;
    }
    &.btn-secondary:hover {
        background: #FCC615;
        color: #000000;
    }
    &.btn-secondary a, &.btn-secondary a:hover {
        color: #ffffff;
        font-size: 0.9rem;
        color: #000000;
    }
    &.btn-secondary a:hover {
        text-decoration: none;
        color: #000000;
    }
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #000000;
    background-color: #FCC615;
    border-radius: 0.8rem;
}
.pointer {
    cursor: pointer;
}

.full-width {
    width: 100% !important;
}

.blur {
    opacity: 0.2;
}

.table-hover tbody tr:hover {
    background-color: initial;
}

.dropdown-menu {
    background-color: #eaeaea;
}
.dropdown-header {
    font-weight: bolder;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #FCC615;
}

