.table {
    thead {
        tr {
            th {
                vertical-align: middle;
            }
        }
    }
    tbody {
        tr {
            td {
                vertical-align: middle;
            }
        }
    }
}