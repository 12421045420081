
.my-profile {
    background: #ffffff;
    border-radius: 3px;
    min-height: 250px;
    color: #000000;
    .login-alert {
        padding: 50px 25px; 
    }
    .balances {
        padding: 35px;
    }
}